import Vue from "vue";
import service from "@/store/services/product-service";
import Localbase from "localbase";

let db = new Localbase("db");
db.config.debug = false;

const state = {
  isVisibleDialog: false,
  isVisibleShow: false,
  isVisibleShowLot: false,
  isVisibleDeleteDialog: false,

  isLoading: false,
  isLoadingProducts: false,
  isLoadingProduct: false,

  hubID: null,

  product: {
    has_variants: 0,
    type: "simple",
  },

  listProducts: [],

  meta: { totalItems: null },
  params: {},
  currentTab: "general-info",
  lastCode: null,

  selectedHubIds: [],
  selectedAttributeIds: [],
  importOperationId: null,
  importStatus: {},
};

const mutations = {
  IS_VISIBLE_DIALOG(state, is_visible) {
    Vue.set(state, "isVisibleDialog", is_visible);
  },

  IS_VISIBLE_SHOW(state, is_visible) {
    Vue.set(state, "isVisibleShow", is_visible);
  },
  IS_VISIBLE_SHOW_LOT(state, is_visible) {
    Vue.set(state, "isVisibleShowLot", is_visible);
  },

  IS_VISIBLE_DELETE_DIALOG(state, is_visible) {
    Vue.set(state, "isVisibleDeleteDialog", is_visible);
  },

  HAS_VARIANTS(state, has_variants) {
    Vue.set(state.product, "has_variants", has_variants);
  },

  SET_HUB_ID(state, hub_id) {
    state.hubID = hub_id;
  },

  CLEAN_LIST(state) {
    Vue.set(state, "listProducts", []);
  },

  CLEAN_SELECTED_HUB_IDS(state) {
    state.selectedHubIds = [];
  },

  SELECTED_HUB_IDS(state, hubIds) {
    if (hubIds) {
      state.selectedHubIds = hubIds;
    } else if (state.product.hubs) {
      state.selectedHubIds = state.product.hubs.map((h) => h.id) || [];
    } else {
      state.selectedHubIds = [];
    }
  },

  CLEAN_SELECTED_ATTRIBUTE_IDS(state) {
    state.selectedAttributeIds = [];
  },

  SELECTED_ATTRIBUTE_IDS(state, attributeIds) {
    if (attributeIds) {
      state.selectedAttributeIds = attributeIds;
    } else if (state.product.attibute_ids) {
      state.selectedAttributeIds = state.product.attibute_ids || [];
    } else {
      state.selectedAttributeIds = [];
    }
  },

  SET_PRODUCT(state, product) {
    Vue.set(state, "product", product);
  },

  SET_IMPORT_OPERATION(state, id) {
    Vue.set(state, "importOperationId", id);
  },

  SET_IMPORT_STATUS(state, status) {
    Vue.set(state, "importStatus", status);
  },

  EDITED_HUB(state, hubs) {
    Vue.set(state.product, "hubs", hubs);
  },

  EDITED_VARIANT(state, variants) {
    Vue.set(state.product, "variants", variants);
  },

  SET_PRODUCTS(state, products) {
    Vue.set(state, "listProducts", products);
  },

  CONCAT_PRODUCTS(state, products) {
    Vue.set(state, "listProducts", state.listProducts.concat(products));
  },

  SET_META(state, data) {
    state.meta.page = data.meta.current_page;
    state.meta.itemsPerPage = data.meta.per_page;
    state.meta.lastPage = data.meta.last_page;
    state.meta.totalItems = data.meta.total;
    // Vue.set(state, "listProducts", products);
  },

  ADD_PRODUCT(state, product) {
    state.listProducts.push(Object.assign({}, product));
  },

  UPDATE_PRODUCT(state, product) {
    const item = state.listProducts.find((item) => item.id === product.id);
    if (item) {
      Object.assign(item, product);
    }
  },

  DELETE_PRODUCT(state, product) {
    state.listProducts.splice(
      state.listProducts.map((o) => o.id).indexOf(product.id),
      1
    );
  },

  SET_PARAMS(state, payload) {
    Vue.set(state, "params", payload);
  },
  SET_LAST_CODE(state, payload) {
    Vue.set(state, "lastCode", payload);
  },
  SET_PACK_ITEM(state, payload) {
    Vue.set(state.product, "pack_item", payload);
  },
  CURRENT_TAB(state, currentTab) {
    Vue.set(state, "currentTab", currentTab);
  },
};

const actions = {
  openShowForm: async (store, product) => {
    store.state.isLoadingProduct = true;
    await service.show({ id: product.id }).then((product) => {
      store.commit("SET_PRODUCT", product);
      store.commit("IS_VISIBLE_SHOW", true);
      store.state.isLoadingProduct = false;
    });
  },
  openShowLot: async (store, product) => {
    store.state.isLoadingProduct = true;
    await service.show({ id: product.id }).then((product) => {
      store.commit("SET_PRODUCT", product);
      store.commit("IS_VISIBLE_SHOW_LOT", true);
      store.state.isLoadingProduct = false;
    });
  },

  openEditForm: async (store, product) => {
    await service.show({ id: product.id }).then((product) => {
      store.commit("SET_PRODUCT", product);
      store.commit("IS_VISIBLE_DIALOG", true);
    });

    if (store.state.product.hubs) {
      store.commit(
        "SELECTED_HUB_IDS",
        store.state.product.hubs.map((item) => item.id)
      );
    }
    // to fix in update product
    if (store.state.product.attibute_ids) {
      store.commit("SELECTED_ATTRIBUTE_IDS", store.state.product.attibute_ids);
    }
  },

  openDeleteForm: (store, product) => {
    store.commit("IS_VISIBLE_DELETE_DIALOG", true);
    store.commit("SET_PRODUCT", Object.assign({}, product));
  },

  closeForm: (store) => {
    store.commit("IS_VISIBLE_DIALOG", false);
    store.commit("IS_VISIBLE_DELETE_DIALOG", false);
    store.commit("IS_VISIBLE_SHOW", false);
    store.commit("IS_VISIBLE_SHOW_LOT", false);
    store.commit("SET_PRODUCT", {});
    store.commit("CLEAN_SELECTED_HUB_IDS");
    store.commit("CLEAN_SELECTED_ATTRIBUTE_IDS");
  },

  closeFormTest: (store, product) => {
    store.commit("IS_VISIBLE_DIALOG", false);
    store.commit("IS_VISIBLE_DELETE_DIALOG", false);
    store.commit("IS_VISIBLE_SHOW", false);
    store.commit("IS_VISIBLE_SHOW_LOT", false);
    store.commit("SET_PRODUCT", { product });
    store.commit("CLEAN_SELECTED_HUB_IDS");
    store.commit("CLEAN_SELECTED_ATTRIBUTE_IDS");
  },

  list: (store, params) => {
    store.state.isLoadingProducts = true;
    return service.list(params).then((data) => {
      store.commit("SET_PRODUCTS", data.products);
      store.commit("SET_META", data);
      store.state.isLoadingProducts = false;
    });
  },

  listWithOffline: (store, params) => {
    if (navigator.onLine) {
      store.dispatch("list", params);
    } else {
      db.collection("products")
        .get()
        .then((payload) => {
          store.commit("SET_PRODUCTS", payload);
        });
    }
  },

  barodesQuery: (store, params) => {
    store.state.isLoading = true;
    return service.listBarcodes(params).then((data) => {
      store.state.isLoading = false;
      return data;
    });
  },

  leaf: (store, params) => {
    store.dispatch("list", { ...params, has_variants: false });
  },

  root: (store, params) => {
    store.state.isLoadingProducts = true;
    return service.list({ ...params, has_parent: false }).then((data) => {
      store.commit("SET_PRODUCTS", data.products);
      store.commit("SET_META", data);
      store.state.isLoadingProducts = false;
    });
  },

  downloadCSV: (store, option) => {
    return service.downloadCSV(option);
  },

  exportAlertsCSV: (store, option) => {
    return service.exportAlertsCSV(option);
  },

  downloadTemplate: () => {
    return service.downloadTemplate();
  },

  downloadFailedResult: (store) => {
    return service.downloadFailedResult(store.state.importOperationId);
  },

  show: (store, params) => {
    store.state.isLoadingProduct = true;
    return service.show(params).then((product) => {
      store.commit("SET_PRODUCT", product);
      store.state.isLoadingProduct = false;
    });
  },

  listSelectedHubIds: (store) => {
    return store.commit("SELECTED_HUB_IDS");
  },

  listselectedAttributeIds: (store) => {
    return store.commit("SELECTED_ATTRIBUTE_IDS");
  },

  add: (store, product) => {
    return service.add(product).then((product) => {
      store.commit("ADD_PRODUCT", product);
    });
  },

  update: (store, product) => {
    return service.update(product).then((product) => {
      store.commit("UPDATE_PRODUCT", product);
    });
  },

  attachAlerts: (store, product) => {
    return service.attachAlerts(product).then((product) => {
      store.commit("UPDATE_PRODUCT", product);
    });
  },

  destroy: (store) => {
    if (store.state.product) {
      const product = store.state.product;
      return service.destroy(product).then(() => {
        store.commit("DELETE_PRODUCT", product);
      });
    }
  },

  uploadCSV: (store, payload) => {
    return service.uploadCSV(payload).then((data) => {
      store.commit("SET_IMPORT_OPERATION", data.id);
    });
  },
  uploadAlertsCSV: (store, payload) => {
    return service.uploadAlertsCSV(payload).then((data) => {
      store.commit("SET_PRODUCTS", data.products);
      store.commit("SET_META", data);
    });
  },

  importStatus: (store) => {
    return service
      .getImportStatus(store.state.importOperationId)
      .then((data) => {
        store.commit("SET_IMPORT_STATUS", data);
      });
  },
  cleanImportStatus: (store) => {
    store.commit("SET_IMPORT_STATUS", {});
  },

  getLastProductCode: (store) => {
    return service.getLastProductCode().then((code) => {
      store.commit("SET_LAST_CODE", code);
    });
  },

  setParams: (store, params) => {
    store.commit("SET_PARAMS", params);
  },

  clean: (store) => {
    store.commit("SET_PARAMS", {});
    store.commit("CLEAN_LIST");
  },
};

const getters = {
  isVisibleDialog(state) {
    return state.isVisibleDialog;
  },

  isLoadingProducts(state) {
    return state.isLoadingProducts;
  },

  isLoadingProduct(state) {
    return state.isLoadingProduct;
  },

  getHubID(state) {
    return state.hubID;
  },

  product(state) {
    return state.product;
  },

  listProducts(state) {
    return state.listProducts;
  },

  meta(state) {
    return state.meta;
  },

  params(state) {
    return state.params;
  },
  getLastCode(state) {
    return state.lastCode;
  },

  importStatus(state) {
    return state.importStatus;
  },

  currentTab(state) {
    return state.currentTab;
  },
};

const products = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default products;
