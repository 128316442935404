import service from "@/store/services/category-service";
import Vue from "vue";
import _ from "lodash";

const state = {
  isLoadingCategories: false,
  isVisibleDialog: false,
  isVisibleShow: false,
  isVisibleDeleteDialog: false,
  listCategories: [],

  listShelves: [],
  listRoots: [],
  listLeafs: [],

  category: {},
  categoryOld: {},
};

const mutations = {
  IS_VISIBLE_DIALOG(state, is_visible) {
    Vue.set(state, "isVisibleDialog", is_visible);
  },
  IS_VISIBLE_DELETE_DIALOG(state, is_visible) {
    Vue.set(state, "isVisibleDeleteDialog", is_visible);
  },
  IS_VISIBLE_SHOW(state, is_visible) {
    Vue.set(state, "isVisibleShow", is_visible);
  },
  SET_CATEGORY(state, category) {
    Vue.set(state, "category", category);
  },
  SET_CATEGORY_OLD(state, category) {
    Vue.set(state, "categoryOld", category);
  },
  SET_CATEGORIES(state, list_categories) {
    state.listCategories = list_categories;
  },
  SET_LEAFS(state, listLeafs) {
    state.listLeafs = listLeafs;
  },
  SET_ROOTS(state, listRoots) {
    state.listRoots = listRoots;
  },
  SET_SHELVES(state, listShelves) {
    state.listShelves = listShelves;
  },

  ADD_CATEGORY(state, category) {
    state.listCategories.push(category);
  },

  UPDATE_CATEGORY(state, category) {
    const item = state.listCategories.find((item) => item.id === category.id);
    Object.assign(item, category);
  },

  DELETE_CATEGORY(state, category) {
    state.listCategories.splice(
      state.listCategories.map((o) => o.id).indexOf(category.id),
      1
    );
  },

  APPEND_ROOTS: (state, categories) => {
    state.listRoots = _.unionBy(categories, state.listRoots, "name");
  },
};

const actions = {
  openEditForm: (store, payload) => {
    store.state.isLoadingCategories = true;

    service.show({ id: payload.id }).then((category) => {
      store.commit("IS_VISIBLE_DIALOG", true);
      store.commit("SET_CATEGORY", Object.assign({}, category));
      store.commit("SET_CATEGORY_OLD", Object.assign({}, category));
      store.state.isLoadingCategories = false;
    });
  },
  openShowForm: (store, payload) => {
    store.state.isLoadingCategories = true;
    service.show({ id: payload.id }).then((category) => {
      store.commit("IS_VISIBLE_SHOW", true);
      store.commit("SET_CATEGORY", Object.assign({}, category));
      store.state.isLoadingCategories = false;
    });
  },

  closeForm: (store) => {
    store.commit("IS_VISIBLE_DIALOG", false);
    store.commit("IS_VISIBLE_DELETE_DIALOG", false);
    store.commit("IS_VISIBLE_SHOW", false);
    store.commit("SET_CATEGORY", {});
    store.commit("SET_CATEGORY_OLD", {});
  },

  list: (store) => {
    store.state.isLoadingCategories = true;
    return service
      .list({})
      .then((list_categories) => {
        store.commit("SET_CATEGORIES", list_categories);
        store.state.isLoadingCategories = false;
      })
      .catch((error) => {
        store.state.isLoadingCategories = false;
        throw error;
      });
  },

  listShelves: (store) => {
    store.state.isLoadingCategories = true;
    return service
      .list({ level: "1" })
      .then((list_categories) => {
        store.commit("SET_SHELVES", list_categories);
        store.state.isLoadingCategories = false;
      })
      .catch((error) => {
        store.state.isLoadingCategories = false;
        throw error;
      });
  },
  listRoots: (store) => {
    store.state.isLoadingCategories = true;
    return service
      .list({ level: "2" })
      .then((list_categories) => {
        store.commit("SET_ROOTS", list_categories);
        store.state.isLoadingCategories = false;
      })
      .catch((error) => {
        store.state.isLoadingCategories = false;
        throw error;
      });
  },

  listLeafs: (store, params) => {
    store.state.isLoadingCategories = true;
    return service
      .list({ level: "3", ...params })
      .then((list_categories) => {
        store.commit("SET_LEAFS", list_categories);
        store.state.isLoadingCategories = false;
      })
      .catch((error) => {
        store.state.isLoadingCategories = false;
        throw error;
      });
  },

  add: (store, category) => {
    return service.add(category).then((category) => {
      store.commit("ADD_CATEGORY", category);
      store.dispatch("list", { page: 1, itemsPerPage: 30 });
    });
  },

  update: (store, category) => {
    return service.update(category).then((category) => {
      store.commit("UPDATE_CATEGORY", category);
    });
  },

  destroy: (store, category) => {
    return service.destroy(category).then(() => {
      store.commit("DELETE_CATEGORY", category);
    });
  },

  destroyUnused: () => {
    return service.destroyUnused();
  },

  uploadCSV: (store, payload) => {
    return service.uploadCSV(payload).then(() => {
      store.dispatch("list", { page: 1, itemsPerPage: 30 });
    });
  },

  exportCSV: (store, option) => {
    return service.exportCSV(option);
  },
};

const getters = {
  isLoadingCategories(state) {
    return state.isLoadingCategories;
  },
  category(state) {
    return state.category;
  },
  categoryOld(state) {
    return state.categoryOld;
  },
  listCategories(state) {
    return state.listCategories;
  },
  listShelves(state) {
    return state.listShelves;
  },
  listRoots(state) {
    return state.listRoots;
  },
  listLeafs(state) {
    return state.listLeafs;
  },

  leafCategories(state) {
    return state.listCategories.filter((item) => {
      return item.children.length === 0 && item.status == "active";
    });
  },

  treeCategories(state) {
    return state.listCategories.filter((item) => {
      return !item.parent_id;
    });
  },

  activeCategories(state) {
    return state.listCategories.filter((item) => {
      return item.status == "active";
    });
  },

  getCategoriesForTreeselect(state) {
    function formatData(item) {
      if (item.children && item.children.length > 0) {
        return {
          id: item.id,
          label: item.name,
          children: item.children.map((subitem) => {
            return formatData(subitem);
          }),
        };
      }
      return {
        id: item.id,
        label: item.name,
      };
    }

    const filtred = state.listCategories.filter((item) => {
      return item.status == "active" && !item.parent_id;
    });

    return filtred.map((item) => {
      return formatData(item);
    });
  },
};

const categories = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default categories;
