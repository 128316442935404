import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import alerts from "./modules/alerts-module";
import products from "./modules/products-module";
import categories from "./modules/categories-module";
import brands from "./modules/brands-module";
import orders from "./modules/orders-module";
import statuses from "./modules/statuses-module";
import orderStatuses from "./modules/order-statuses-module";
import paymentMethods from "./modules/payment-methods-module";
import stores from "./modules/stores-module";
import hubs from "./modules/hubs-module";
import inboundStatuses from "./modules/inbound-statuses-module";
import transferStatuses from "./modules/transfer-statuses-module";
import transferProducts from "./modules/transfer-products-module";
import wilayas from "./modules/wilayas-module";
import cities from "./modules/cities-module";
import sections from "./modules/sections-module";
import attributes from "./modules/attributes-module";
import orderOrigins from "./modules/order-origins-module";
import typePrices from "./modules/type-prices-module";
import dash from "./modules/dash-module";
import providers from "./modules/providers-module";
import inboundProducts from "./modules/inbound-products-module";
import accounting from "./modules/accounting-module";
import users from "./modules/users-module";
import roles from "./modules/roles-module";
import permissions from "./modules/permissions-module";
import syncProducts from "./modules/sync-products-module";
import badges from "./modules/badges-module";
import supplyOrders from "./modules/supply-orders-module";
import statistics from "./modules/statistics-module";
import checkouts from "./modules/checkouts-module";
import inventories from "./modules/inventories-module";
import syncOrderLogs from "./modules/sync-order-logs-module";
import countries from "@/store/modules/countries-module";
import specialCategories from "./modules/special-categories-module";
import deliveryTypes from "./modules/delivery-types-module";
import sync from "./modules/sync-module";
import settings from "./modules/settings-module";
import lots from "./modules/lots-module";
import promotions from "./modules/promotions-module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    alerts,
    products,
    categories,
    brands,
    orders,
    statuses,
    orderStatuses,
    paymentMethods,
    stores,
    hubs,
    transferStatuses,
    inboundStatuses,
    transferProducts,
    countries,
    wilayas,
    cities,
    sections,
    attributes,
    orderOrigins,
    typePrices,
    dash,
    providers,
    inboundProducts,
    accounting,
    users,
    roles,
    permissions,
    syncProducts,
    badges,
    supplyOrders,
    statistics,
    checkouts,
    syncOrderLogs,
    specialCategories,
    deliveryTypes,
    sync,
    inventories,
    settings,
    lots,
    promotions,
  },

  state: {
    barColor: "rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)",
    barImage:
      "https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg",
    drawer: null,
    page_subtitle: "",
    subtitle_id: "",
  },
  mutations: {
    SET_BAR_IMAGE(state, payload) {
      state.barImage = payload;
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload;
    },

    SET_PAGE_SUBTITLE(state, payload) {
      state.page_subtitle = payload;
    },
    SET_SUBTITLE_ID(state, payload) {
      state.subtitle_id = payload;
    },
  },
  actions: {},
});
