import axios from "@/helpers/axios";
import authService from "@/store/services/auth-service";

const base_url = process.env.VUE_APP_API_BASE_URL;

async function list(params) {
  const res = await axios.get(
    `${base_url}supply-orders?include=toHub,fromProvider`,
    {
      params: params,
      headers: authService.authHeader(),
    }
  );
  return res.data;
}
async function listDelivery() {
  const res = await axios.get(`${base_url}delivery_types`, {
    headers: authService.authHeader(),
  });
  return res.data;
}

async function downloadCSV(option) {
  const res = await axios.get(`${base_url}supply_orders_export_csv`, {
    params: option,
    headers: authService.authHeader(),
    responseType: "blob",
  });
  return res.data;
}

async function getInvoice(supplyOrder) {
  const res = await axios.get(
    `${base_url}generate_invoice_supply_order_a4/${supplyOrder.id}`,
    { headers: authService.authHeader(), responseType: "blob" }
  );
  return res.data;
}

async function add(supplyOrder) {
  const res = await axios.post(`${base_url}supply-orders`, supplyOrder, {
    headers: authService.authHeader(),
  });
  return res.data.supply_order;
}

async function updateProduct(product) {
  const res = await axios.put(
    `${base_url}supply-orders/${product.supply_order_id}/products/${product.id}`,
    product,
    {
      headers: authService.authHeader(),
    }
  );
  return res.data.product;
}

async function addToCart(orderItem) {
  const res = await axios.put(
    `${base_url}supply-orders/${orderItem.supply_order_id}/products/${orderItem.id}`,
    orderItem,
    {
      headers: authService.authHeader(),
    }
  );
  return res.data.supply_order;
}

async function removeFromCart(orderItem) {
  const res = await axios.delete_method(
    `${base_url}supply-orders/${orderItem.supply_order_id}/products/${orderItem.id}`,
    {
      headers: authService.authHeader(),
    }
  );

  return res.data.supply_order;
}

async function submitSupplyOrder(params) {
  const { expected_delivery_date, notes } = params;
  const res = await axios.put(
    `${base_url}supply-orders/${params.supply_order_id}/submit`,
    {
      expected_delivery_date,
      notes,
    },
    {
      headers: authService.authHeader(),
    }
  );
  return res.data;
}

async function updateSupplyOrder(params) {
  const res = await axios.put(`${base_url}supply-orders/${params.id}`, params, {
    headers: authService.authHeader(),
  });
  return res.data.supply_order;
}

async function show(options) {
  const res = await axios.get(
    `${base_url}supply-orders/${options.id}?include=products,fromProvider,shelf,toHub,cancelledBy,inbounds`,
    {
      headers: authService.authHeader(),
    }
  );
  return res.data.supply_order;
}

async function update(supplyOrder) {
  const res = await axios.put(
    `${base_url}supply-orders/${supplyOrder.id}`,
    supplyOrder,
    { headers: authService.authHeader() }
  );
  return res.data.supply_order;
}

async function updateStatus(supplyOrder) {
  const res = await axios.put(
    `${base_url}supply-orders/${supplyOrder.id}/confirmation`,
    supplyOrder,
    { headers: authService.authHeader() }
  );
  return res.data.supply_order;
}
async function updateDeliveryDate(supplyOrder) {
  console.log("okk");
  const res = await axios.put(
    `${base_url}supply-orders/${supplyOrder.id}/expected-delivery-date`,
    supplyOrder,
    { headers: authService.authHeader() }
  );
  return res.data.supply_order;
}

async function confirm(supplyOrder) {
  const res = await axios.put(
    `${base_url}validate_supply_order/${supplyOrder.id}`,
    {},
    { headers: authService.authHeader() }
  );
  return res.data.supply_order;
}
async function setToReceived(supplyOrder) {
  const res = await axios.put(
    `${base_url}supply-orders/set-received/${supplyOrder.id}`,
    {},
    { headers: authService.authHeader() }
  );
  return res.data.supply_order;
}

function destroy(supplyOrder) {
  return axios.delete_method(`${base_url}supply-orders/${supplyOrder.id}`, {
    headers: authService.authHeader(),
  });
}

async function uploadCSV(csv) {
  const res = await axios.post(`${base_url}supply-orders/upload_csv`, csv, {
    headers: {
      "Content-Type": "multipart/form-data",
      ...authService.authHeader(),
    },
  });
  return res.data;
}
async function duplicate(supplyOrder) {
  const res = await axios.post(
    `${base_url}supply-orders/${supplyOrder.id}/duplicate`,
    supplyOrder,
    {
      headers: authService.authHeader(),
    }
  );
  return res.data;
}
async function cancel(params) {
  const { reason_cancellation } = params;
  const res = await axios.put(
    `${base_url}supply-orders/${params.supply_order_id}/cancel`,
    {
      reason_cancellation,
    },
    {
      headers: authService.authHeader(),
    }
  );
  return res.data;
}

async function settings(params) {
  const { automatic_cancellation, cancellation_period, manual_cancellation } =
    params;
  const res = await axios.put(
    `${base_url}supply-orders-settings`,
    {
      automatic_cancellation,
      cancellation_period,
      manual_cancellation,
    },
    {
      headers: authService.authHeader(),
    }
  );
  return res.data;
}
async function settingsList() {
  const res = await axios.get(`${base_url}supply-orders-settings`, {
    headers: authService.authHeader(),
  });
  return res.data;
}

export default {
  list,
  downloadCSV,
  add,
  show,
  update,
  destroy,
  confirm,
  setToReceived,
  getInvoice,
  uploadCSV,
  updateProduct,
  listDelivery,
  addToCart,
  removeFromCart,
  submitSupplyOrder,
  updateSupplyOrder,
  updateStatus,
  duplicate,
  updateDeliveryDate,
  cancel,
  settings,
  settingsList,
};
