import Vue from "vue";
import service from "@/store/services/inventory-service";

const state = {
  isVisibleProductShow: false,
  isVisibleProductEdit: false,
  isVisibleDeleteDialog: false,

  isLoadingInventories: false,
  isLoadingProducts: false,
  isLoadingInventory: false,
  isLoadingProduct: false,

  inventory: {},
  product: {},

  listInventories: [],
  meta: {},

  listProducts: [],
  productsMeta: {},
  listProductsArbitration: [],
  productsMetaArbitration: {},
};

const mutations = {
  IS_VISIBLE_PRODUCT_SHOW(state, is_visible) {
    Vue.set(state, "isVisibleProductShow", is_visible);
  },

  IS_VISIBLE_PRODUCT_EDIT(state, is_visible) {
    Vue.set(state, "isVisibleProductEdit", is_visible);
  },

  IS_VISIBLE_PRODUCT_DELETE_DIALOG(state, is_visible) {
    Vue.set(state, "isVisibleProductDeleteDialog", is_visible);
  },

  IS_VISIBLE_DELETE_DIALOG(state, is_visible) {
    Vue.set(state, "isVisibleDeleteDialog", is_visible);
  },

  SET_INVENTORIES(state, inventories) {
    state.listInventories = inventories;
  },

  SET_PRODUCTS(state, products) {
    state.listProducts = products;
  },

  SET_INVENTORY(state, inventory) {
    state.inventory = inventory;
  },

  SET_PRODUCT(state, product) {
    state.product = product;
  },

  ADD_INVENTORY(state, inventory) {
    state.listInventories.push(inventory);
  },

  CLEAN_LIST(state) {
    Vue.set(state, "listInventories", []);
  },

  UPDATE_INVENTORY(state, inventory) {
    const item = state.listInventories.find((item) => item.id === inventory.id);
    if (item) Object.assign(item, inventory);
  },

  UPDATE_PRODUCT(state, product) {
    const item = state.listProducts.find((item) => item.id === product.id);
    if (item) Object.assign(item, product);
    else state.listProducts.push(product);
  },

  DELETE_PRODUCT(state, product) {
    state.listProducts.splice(
      state.listInventories.map((item) => item.id === product.id),
      1
    );
  },

  DELETE_INVENTORY(state, inventory) {
    state.listInventories.splice(
      state.listInventories.map((item) => item.id).indexOf(inventory.id),
      1
    );
  },

  SET_META(state, data) {
    state.meta.page = data.meta.current_page;
    state.meta.itemsPerPage = data.meta.per_page;
    state.meta.lastPage = data.meta.last_page;
    state.meta.totalItems = data.meta.total;
  },

  SET_PRODUCTS_META(state, data) {
    state.productsMeta.page = data.meta.current_page;
    state.productsMeta.itemsPerPage = data.meta.per_page;
    state.productsMeta.lastPage = data.meta.last_page;
    state.productsMeta.totalItems = data.meta.total;
  },
  SET_PRODUCTS_ARBIT(state, products) {
    state.listProductsArbitration = products;
  },
  SET_PRODUCTS_ARBIT_META(state, data) {
    state.productsMetaArbitration.page = data.meta.current_page;
    state.productsMetaArbitration.itemsPerPage = data.meta.per_page;
    state.productsMetaArbitration.lastPage = data.meta.last_page;
    state.productsMetaArbitration.totalItems = data.meta.total;
  },
};

const actions = {
  openShowProduct: async (store, payload) => {
    await service.show(payload.inventory_id, payload.id).then((product) => {
      store.commit("IS_VISIBLE_PRODUCT_SHOW", true);
      store.commit("SET_PRODUCT", product);
    });
  },

  openEditForm: async (store, payload) => {
    await service.show(payload).then((inventory) => {
      store.commit("SET_INVENTORY", inventory);
    });
  },

  show: async (store, payload) => {
    await service.show(payload).then((inventory) => {
      store.commit("SET_INVENTORY", inventory);
    });
  },

  openEditProduct: async (store, payload) => {
    await service
      .showProduct(payload.inventory_id, payload.id)
      .then((product) => {
        store.commit("IS_VISIBLE_PRODUCT_EDIT", true);
        store.commit("SET_PRODUCT", product);
      });
  },

  addProduct: async (store, payload) => {
    await service
      .showProduct(payload.inventory_id, payload.id)
      .then((product) => {
        store.commit("SET_PRODUCT", product);
      });
  },

  openDeleteInventoryItemDialog: (store, payload) => {
    store.commit("SET_PRODUCT", payload);
    store.commit("IS_VISIBLE_PRODUCT_DELETE_DIALOG", true);
  },

  clearState: (store) => {
    store.commit("SET_INVENTORIES", []);
    store.commit("SET_PRODUCTS", []);
  },

  closeForm: (store) => {
    store.commit("SET_INVENTORY", {});
    store.commit("IS_VISIBLE_DELETE_DIALOG", false);
  },
  openDeleteDialog: (store, payload) => {
    store.commit("SET_INVENTORY", payload);
    store.commit("IS_VISIBLE_DELETE_DIALOG", true);
  },

  closeProductForm: (store) => {
    store.commit("IS_VISIBLE_PRODUCT_SHOW", false);
    store.commit("IS_VISIBLE_PRODUCT_EDIT", false);
    store.commit("IS_VISIBLE_PRODUCT_DELETE_DIALOG", false);
    store.commit("SET_PRODUCT", {});
  },

  getProductBySKU: async (store, payload) => {
    await service
      .getProductBySKU(payload.inventory_id, payload)
      .then((product) => {
        store.commit("SET_PRODUCT", product);
      });
  },

  list: (store, params) => {
    store.state.isLoadingInventories = true;
    return service
      .list(params)
      .then((data) => {
        store.commit("SET_INVENTORIES", data.inventories);
        store.commit("SET_META", data);
        store.state.isLoadingInventories = false;
      })
      .catch((error) => {
        store.state.isLoadingInventories = false;
        throw error;
      });
  },

  listProducts: (store, params) => {
    store.state.isLoadingProducts = true;

    return service
      .listProducts(params)
      .then((data) => {
        store.commit("SET_PRODUCTS", data.products);
        store.commit("SET_PRODUCTS_META", data);
        store.state.isLoadingProducts = false;
      })
      .catch((error) => {
        store.state.isLoadingProducts = false;
        throw error;
      });
  },

  listProductsWithQuantityGap: (store, params) => {
    store.state.isLoadingProducts = true;

    return service
      .listProductsWithQuantityGap(params)
      .then((data) => {
        store.commit("SET_PRODUCTS", data.products);
        store.commit("SET_PRODUCTS_META", data);
        store.state.isLoadingProducts = false;
      })
      .catch((error) => {
        store.state.isLoadingProducts = false;
        throw error;
      });
  },

  listProductsArbitration: (store, params) => {
    store.state.isLoadingProducts = true;

    return service
      .listProductsArbitration(params)
      .then((data) => {
        store.commit("SET_PRODUCTS_ARBIT", data.products);
        store.commit("SET_PRODUCTS_ARBIT_META", data);
        store.state.isLoadingProducts = false;
      })
      .catch((error) => {
        store.state.isLoadingProducts = false;
        throw error;
      });
  },

  add: (store, inventory) => {
    return service.add(inventory).then((item) => {
      store.commit("ADD_INVENTORY", item);
      store.commit("SET_INVENTORY", item);
    });
  },

  addAllProducts: async (store, params) => {
    // TODO: FIX the process, refresh inventory products list after this
    return await service.addAllProducts(store.state.inventory.id, params);
  },

  removeAllProducts: async (store, params) => {
    return await service.removeAllProducts(store.state.inventory.id, params);
  },

  update: (store, inventory) => {
    return service.update(inventory).then((item) => {
      store.commit("UPDATE_INVENTORY", item);
    });
  },

  updateProduct: (store, payload) => {
    return service.updateProduct(payload, payload.inventory_id).then((item) => {
      store.commit("UPDATE_PRODUCT", item);
      return item;
    });
  },

  removeProduct: (store, payload) => {
    return service
      .removeProduct(payload.id, payload.inventory_id)
      .then((item) => {
        store.commit("DELETE_PRODUCT", item);
      });
  },

  confirm: (store, inventory) => {
    return service.confirm(inventory).then((item) => {
      store.commit("UPDATE_INVENTORY", item);
    });
  },

  checkPhysicalQte: async (store, inventory_id) => {
    await service.checkPhysicalQte(inventory_id);
  },

  checkGapReasons: async (store, inventory_id) => {
    await service.checkGapReasons(inventory_id);
  },
  lastStep: async (store, inventory_id) => {
    await service.lastStep(inventory_id);
  },

  destroy: (store) => {
    if (store.state.inventory) {
      const inventory = store.state.inventory;
      return service.destroy(inventory).then(() => {
        store.commit("DELETE_INVENTORY", inventory);
      });
    }
  },

  uploadCSV: (store, payload) => {
    return service.uploadCSV(payload).then((data) => {
      store.commit("SET_INVENTORIES", data.inventories);
      store.commit("SET_META", data);
    });
  },
  exportPDF: (store, payload) => {
    return service.exportPDF(payload);
  },
  exportCSV: (store, payload) => {
    return service.exportCSV(payload);
  },
  exportBATCHCSV: (store, payload) => {
    return service.exportBATCHCSV(payload);
  },
  exportBATCHCSVArbitration: (store, payload) => {
    return service.exportBATCHCSVArbitration(payload);
  },
  exportQuantityCSV: (store, payload) => {
    return service.exportQuantityCSV(payload);
  },
  uploadBATCHCSV: (store, payload) => {
    return service.uploadBATCHCSV(payload);
  },
  uploadBATCHCSVArbitration: (store, payload) => {
    return service.uploadBATCHCSVArbitration(payload);
  },
  uploadQuantityCSV: (store, payload) => {
    return service.uploadQuantityCSV(payload);
  },
};

const getters = {
  isVisibleProductEdit(state) {
    return state.isVisibleProductEdit;
  },
  isVisibleProductShow(state) {
    return state.isVisibleProductShow;
  },

  isLoadingInventories(state) {
    return state.isLoadingInventories;
  },

  isLoadingInventory(state) {
    return state.isLoadingInventory;
  },

  editedIndex(state) {
    return state.editedIndex;
  },

  editedInventory(state) {
    return state.editedInventory;
  },

  getInventory(state) {
    return state.inventory;
  },

  listInventories(state) {
    return state.listInventories;
  },

  listProducts(state) {
    return state.listProducts;
  },
  listProductsArbitration(state) {
    return state.listProductsArbitration;
  },

  product(state) {
    return state.product;
  },

  meta(state) {
    return state.meta;
  },

  productsMeta(state) {
    return state.productsMeta;
  },

  productsMetaArbitration(state) {
    return state.productsMetaArbitration;
  },
};

const inventoryProducts = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default inventoryProducts;
