import Localbase from "localbase";

let db = new Localbase("db");
function getOrders() {
  return db.collection("orders").get();
}

function deleteOrderByTrackingNumber( tracking_number ) {
    db.collection("orders")
        .doc(tracking_number)
        .delete();
}

function updateOrderByTrackingNumber( tracking_number, data ) {
    db.collection("orders")
        .doc(tracking_number)
        .update(data);
}

function markOrderAsLogged( tracking_number ) {
    db.collection("orders")
        .doc(tracking_number)
        .update({logged:true});
}

export default {
    getOrders,
    deleteOrderByTrackingNumber,
    updateOrderByTrackingNumber,
    markOrderAsLogged
};
