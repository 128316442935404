import axios from "@/helpers/axios";
import authService from "@/store/services/auth-service";

const base_url = process.env.VUE_APP_API_BASE_URL;

async function list(params) {
  const res = await axios.get(`${base_url}inventories`, {
    params: params,
    headers: authService.authHeader(),
  });
  return res.data;
}
async function listProducts(params) {
  const res = await axios.get(
    `${base_url}inventory-products/list/${params.inventory_id}`,
    { params: params, headers: authService.authHeader() }
  );
  return res.data;
}
async function listProductsWithQuantityGap(params) {
  const res = await axios.get(
    `${base_url}inventory-products/list-with-quantity-gap/${params.inventory_id}`,
    { params: params, headers: authService.authHeader() }
  );
  return res.data;
}
async function listProductsArbitration(params) {
  const res = await axios.get(
    `${base_url}inventory-products/list-arbitration/${params.inventory_id}`,
    { params: params, headers: authService.authHeader() }
  );
  return res.data;
}

async function show(options) {
  const res = await axios.get(`${base_url}inventories/${options.id}`, {
    headers: authService.authHeader(),
  });
  return res.data.inventory;
}

async function showProduct(inventory_id, product_id) {
  const res = await axios.get(
    `${base_url}inventory-products/show/${inventory_id}/${product_id}`,
    {
      headers: authService.authHeader(),
    }
  );
  return res.data.product;
}

async function getProductBySKU(inventory_id, params) {
  const res = await axios.get(
    `${base_url}inventory-products/attach/${inventory_id}`,
    {
      params: params,
      headers: authService.authHeader(),
    }
  );
  return res.data.product;
}

async function add(inventory) {
  const res = await axios.post(`${base_url}inventories`, inventory, {
    headers: authService.authHeader(),
  });
  return res.data.inventory;
}

async function update(inventory) {
  const res = await axios.put(
    `${base_url}inventories/${inventory.id}`,
    inventory,
    { headers: authService.authHeader() }
  );
  return res.data.inventory;
}

async function updateProduct(payload, inventory_id) {
  const res = await axios.put(
    `${base_url}inventory-products/update/${inventory_id}`,
    payload,
    { headers: authService.authHeader() }
  );
  return res.data.product;
}

async function removeProduct(product_id, inventory_id) {
  const res = await axios.post(
    `${base_url}inventory-products/detach/${inventory_id}/${product_id}`,
    {},
    { headers: authService.authHeader() }
  );
  return res.data.product;
}

async function addAllProducts(inventory_id, params) {
  const res = await axios.post(
    `${base_url}inventory-products/attach/${inventory_id}/list`,
    params,
    { headers: authService.authHeader() }
  );
  return res.data.products;
}

async function removeAllProducts(inventory_id, params) {
  const res = await axios.post(
    `${base_url}inventory-products/detach/${inventory_id}/list`,
    params,
    { headers: authService.authHeader() }
  );
  return res.data.products;
}

async function confirm(inventory) {
  const res = await axios.put(
    `${base_url}validate-inventory/${inventory.id}`,
    {},
    { headers: authService.authHeader() }
  );
  return res.data.inventory;
}
async function checkPhysicalQte(inventory_id) {
  const res = await axios.get(
    `${base_url}check-inventory-physical-qte/${inventory_id}`,
    { headers: authService.authHeader() }
  );
  return res;
}
async function checkGapReasons(inventory_id) {
  const res = await axios.get(
    `${base_url}check-inventory-gap-reasons/${inventory_id}`,
    { headers: authService.authHeader() }
  );
  return res;
}
async function lastStep(inventory_id) {
  const res = await axios.get(
    `${base_url}last-step-inventory/${inventory_id}`,
    { headers: authService.authHeader() }
  );
  return res;
}

function exportPDF(inventory) {
  return axios
    .get(`${base_url}inventories/${inventory.id}/export-pdf`, {
      headers: authService.authHeader(),
      responseType: "blob",
    })
    .then((res) => res.data);
}
function exportCSV(inventory) {
  return axios
    .get(`${base_url}inventories/${inventory.id}/export-csv`, {
      headers: authService.authHeader(),
      responseType: "blob",
    })
    .then((res) => res.data);
}

function exportBATCHCSV(inventory) {
  return axios
    .get(`${base_url}inventories/${inventory.id}/export-batch-csv`, {
      headers: authService.authHeader(),
      responseType: "blob",
    })
    .then((res) => res.data);
}

function exportBATCHCSVArbitration(inventory) {
  return axios
    .get(
      `${base_url}inventories/${inventory.id}/export-batch-csv-arbitration`,
      {
        headers: authService.authHeader(),
        responseType: "blob",
      }
    )
    .then((res) => res.data);
}

function exportQuantityCSV(inventory) {
  return axios
    .get(`${base_url}inventories/${inventory.id}/export-quatity-csv`, {
      headers: authService.authHeader(),
      responseType: "blob",
    })
    .then((res) => res.data);
}

async function uploadBATCHCSV(inventory) {
  const res = await axios.post(
    `${base_url}inventories/upload-batch-csv`,
    inventory,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        ...authService.authHeader(),
      },
    }
  );
  return res.data;
}
async function uploadBATCHCSVArbitration(inventory) {
  const res = await axios.post(
    `${base_url}inventories/upload-batch-csv-arbitration`,
    inventory,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        ...authService.authHeader(),
      },
    }
  );
  return res.data;
}

async function uploadQuantityCSV(inventory) {
  const res = await axios.post(
    `${base_url}inventories/upload-quantity-csv`,
    inventory,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        ...authService.authHeader(),
      },
    }
  );
  return res.data;
}

function destroy(inventory) {
  return axios.delete_method(`${base_url}inventories/${inventory.id}`, {
    headers: authService.authHeader(),
  });
}

export default {
  list,
  show,
  add,
  addAllProducts,
  removeProduct,
  removeAllProducts,
  update,
  destroy,
  confirm,
  listProducts,
  listProductsWithQuantityGap,
  listProductsArbitration,
  updateProduct,
  showProduct,
  getProductBySKU,
  checkPhysicalQte,
  checkGapReasons,
  lastStep,
  exportPDF,
  exportCSV,
  exportBATCHCSV,
  exportBATCHCSVArbitration,
  uploadBATCHCSV,
  uploadBATCHCSVArbitration,
  exportQuantityCSV,
  uploadQuantityCSV,
};
