import axios from "@/helpers/axios";
import authService from "@/store/services/auth-service";

const base_url = process.env.VUE_APP_API_BASE_URL;

async function listProducts(options) {
  const res = await axios.get(`${base_url}promotions/list-products`, {
    params: options,
    headers: authService.authHeader(),
  });
  return res.data;
}

async function getProduct(promotion_id, product_id) {
  const res = await axios.get(
    `${base_url}promotions/${promotion_id}/find-product/${product_id}`,
    {
      headers: authService.authHeader(),
    }
  );
  return res.data;
}

async function schedule(promotion_id, params) {
  const res = await axios.post(
    `${base_url}promotions/${promotion_id}/schedule`,
    params,
    {
      headers: authService.authHeader(),
    }
  );
  return res.data;
}

async function cancel(promotion_id, { reason }) {
  const res = await axios.post(
    `${base_url}promotions/${promotion_id}/cancel`,
    {
      reason,
    },
    {
      headers: authService.authHeader(),
    }
  );
  return res.data;
}

async function getPromotionDetails(promotion_id, options) {
  const res = await axios.get(`${base_url}promotions/${promotion_id}`, {
    params: options,
    headers: authService.authHeader(),
  });
  return res.data;
}

async function list(params) {
  const res = await axios.get(`${base_url}promotions`, {
    headers: authService.authHeader(),
    params,
  });
  return res.data;
}

async function submit(promotion) {
  const res = await axios.post(`${base_url}promotions`, promotion, {
    headers: authService.authHeader(),
  });
  return res.data;
}

async function toggleSelectBatch(params) {
  const { promotion, product, lot_id } = params;
  const res = await axios.post(
    `${base_url}promotions/${promotion.id}/products/${product.id}/toggle`,
    { lot: lot_id },
    {
      headers: authService.authHeader(),
    }
  );
  return res.data;
}

export default {
  list,
  getPromotionDetails,
  listProducts,
  getProduct,
  submit,
  schedule,
  cancel,
  toggleSelectBatch,
};
